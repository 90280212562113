<template>
  <div>
    <EditorInput
      :placeholder="placeholder"
      :class_style="class_style"
      :disabled="disabled"
      :id="id"
      :prop_text="prop_text"
      @onChangeContent="onEditorInput"
    />
  </div>
</template>

<script>
import EditorInput from '@/components/EditorInput.vue'

export default {
  name: 'CkeditorNormal',
  props: ['prop_text', 'id', 'class_style', 'placeholder', 'disabled'],
  components: { EditorInput },
  data () {
    return {
      text: ''
    }
  },

  mounted () {
    this.text = this.prop_text
    // let self = this
    this.$nextTick(function () {
      // console.log('object')
      // document.getElementById(this.id).addEventListener('scroll', (ev) => {
      //   var clipboardData = ev.originalEvent.clipboardData
      //   if (clipboardData) {
      //     if (clipboardData.items.length === 0) { return }
      //     window.$.each(clipboardData.items, function (i, item) {
      //       console.log(item)
      //       if (item.type.indexOf('image') !== -1) {
      //         self.insertBinaryImage(item.getAsFile())
      //       }
      //     })
      //     return false
      //   }
      // })
    })
  },
  methods: {
    onEditorInput (e) {
      this.$emit('onEditorInput', e)
    },
    pane () {
      console.log('object')
    },
    insertBinaryImage (file) {
      var reader = new FileReader()

      reader.addEventListener('loadend', function () {
        var img = new Image()
        img.src = reader.result

        window
          .$('#images')
          .empty()
          .append(img)
      })

      reader.readAsDataURL(file)
    }
  }
}
</script>

<style scoped></style>
