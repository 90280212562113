<template>
  <el-dropdown>
    <button class="border-0 rounded-lg bg-blue-900 text-white text-xs py-1" type="button">
      <span>{{ label }}</span>
      <i class="el-icon-caret-bottom" />
    </button>
    <el-dropdown-menu v-if="items" slot="dropdown">
      <el-dropdown-item v-for="(item, index) in items" :key="index">
        <div class="capitalize">
          {{ $t(item.toLowerCase()) }}
        </div>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: 'DropdownButton',
  props: {
    label: {
      type: String,
      default: 'Button'
    },
    items: {
      type: Array
    }
  },
  data () {
    return {
    }
  }
}
</script>
