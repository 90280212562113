<template>
  <div :id="`${id}-input`">
    <vue-editor :id="id" ref="vue2Editor" class="editor-input" :class="class_style" v-model="content"
      :editorToolbar="customToolbar" :disabled="disabled" :placeholder="placeholder"></vue-editor>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'

export default {
  name: 'EditorInput',
  components: { VueEditor },
  props: {
    id: {
      type: String | Number,
      required: true
    },
    prop_text: String,
    isPasteImage: Boolean,
    class_style: String,
    disabled: Boolean,
    placeholder: String
  },
  data () {
    return {
      content: '',
      customToolbar: [
        [
          'bold',
          'italic',
          'underline'
        ],
        ['link'],
        [{ list: 'ordered' },
          { list: 'bullet' }
        ],
        [{ align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }]
      ]
    }
  },
  mounted () {
    this.onPaste()
    delete this.$refs.vue2Editor.quill?.keyboard?.bindings[9]
    this.content = this.prop_text
  },
  watch: {
    content (value) {
      this.$emit('onChangeContent', value)
    },
    prop_text () {
      this.content = this.prop_text
    }
  },
  methods: {
    onPaste () {
      const editorEl = document.getElementById(`${this.id}-input`)
      if (!editorEl) return
      editorEl.addEventListener('paste', e => {
        e.stopPropagation()
        e.preventDefault()
        let text = e.clipboardData.getData('text')
        const textFormat = text.replaceAll(/\\\n\n/gi, '\\\n')

        // check paste file
        if (e.clipboardData?.files && e.clipboardData?.files?.length > 0 && this.isPasteImage) {
          const file = e.clipboardData.files[0]
          this.$emit('onPasteFile', file)
        }
        if (document.queryCommandSupported('insertText')) {
          document.execCommand('insertText', false, textFormat)
        } else {
          document.execCommand('paste', false, textFormat)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .editor-input {
    .ql-editor {
      min-height: 60px;
      color: black
    }

  }
}
</style>