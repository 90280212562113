<template>
  <div>
    <EditorInput :class_style="class_style" :placeholder="placeholder" :id="id" :disabled="disabled" :prop_text="text"
      @onChangeContent="(content) => text = content" />
  </div>
</template>

<script>
import EditorInput from '@/components/EditorInput.vue'

export default {
  name: 'CkeditorForChat',
  props: ['prop_text', 'id', 'class_style', 'placeholder', 'disabled'],
  components: { EditorInput },
  data () {
    return {
      text: ''
    }
  },
  mounted () { },

  watch: {
    prop_text (val) {
      if (val === '') {
        this.text = val
      }
    }
  },

  methods: {
    onEditorInput (e) {
      this.$emit('onEditorInput', e)
    },
    insertBinaryImage (file) {
      var reader = new FileReader()

      reader.addEventListener('loadend', function () {
        var img = new Image()
        img.src = reader.result

        window
          .$('#images')
          .empty()
          .append(img)
      })

      reader.readAsDataURL(file)
    },
    handleEditorReady (editor) {
      editor.model.document.on('change:data', () => {
        const data = editor.getData()
        this.$emit('onEditorInput', data)
      })
    }
  }
}
</script>

<style scoped></style>
